.about-section {
  width: 75vw;
  height: fit-content;
  margin-bottom: 10vh;
}

.about-title {
  font-size: 1.3rem;
  font-weight: bold;
}

.about-paragraph {
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: var(--fg-secondary);
}
