a {
  text-decoration: none;
}

.contact-section {
  margin-bottom: 5vh;
}

.contact-row {
  color: var(--fg-secondary);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.3rem;
  margin-bottom: 10px;
  transition: all 500ms;
}

.contact-row:hover {
  cursor: pointer;
  color: var(--accent);
}

.contact-label {
  margin-left: 10px;
}
