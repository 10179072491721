.experience-section {
  width: 75vw;
  margin-bottom: 8vh;
}

.experience-title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 5vh;
}

.experience-card {
  display: flex;
  flex-direction: column;
}

.experience-card-primary {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--fg);
  margin: 5px 0;
}

.experience-card-secondary {
  color: var(--fg-secondary);
}

.experience-card-skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
