/* Page Wrapper */
#home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Top Section - Name */
.top-section {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.title {
  font-size: 3rem;
}

.gradient-text {
  background: linear-gradient(to right, rgb(255, 255, 255), rgb(121, 121, 121));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  color: var(--fg-secondary);
  opacity: 0;
  animation: fade-in 2s ease-in forwards;
  animation-delay: 1s;
}

.social-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  opacity: 0;
  animation: fade-in 1.8s ease-in forwards;
  animation-delay: 1.5s;
}

.social-link {
  font-size: 1.8rem;
  margin: 0 10px;
  color: var(--fg-secondary);
  transition: all 0.5s;
}

.social-link:hover {
  cursor: pointer;
  scale: 1.1;
  color: var(--accent);
}

/* About Section */
#other-sections {
  opacity: 0;
  animation: fade-in 1s ease-in forwards;
  animation-delay: 1.8s;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1.5s ease-in;
}
