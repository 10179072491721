a {
  text-decoration: none;
  color: var(--fg);
}

.projects-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 8vh;
}

.projects-title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 5vh;
}

.project-card {
  display: flex;
  flex-direction: column;
  max-width: 70vw;
  margin: 15px 0;
  transition: all 500ms;
}

.project-card:hover {
  cursor: pointer;
  border-radius: 15px;
  padding: 5px;
  background-color: rgba(34, 34, 34, 0.5);
}

.project-card:hover .project-card-primary {
  color: var(--accent);
}

.project-card:hover .project-card-link {
  color: var(--accent);
}

.project-card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.project-card-primary {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--fg);
  margin-bottom: 10px;
}

.project-card-link {
  font-size: 1.2rem;
  margin: 0 5px;
  color: var(--fg);
}

.project-card-description {
  color: var(--fg-secondary);
}

.project-card-skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 15px 0;
  color: var(--fg);
}

.project-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.1rem;
}

.project-footer:hover {
  cursor: pointer;
}

.project-footer-text {
  transition: all 500ms;
}

.project-footer:hover .project-footer-text {
  text-decoration: underline;
  text-decoration-color: var(--accent);
}

.project-footer:hover .project-footer-arrow {
  transform: translateX(5px);
}

.project-footer-arrow {
  margin: 0 5px;
  transition: all 500ms;
}
