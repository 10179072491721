body {
  margin: 0;
}

#navbar-wrapper {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  z-index: 1;
}

#navbar-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: var(--fg);
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 500ms;
}

.initial-load {
  opacity: 0;
  animation: fade-in 2s ease-in forwards;
  animation-delay: 1s;
}

.after-load {
  opacity: 1;
  transition: opacity 2s ease-in-out;
}

/* Navbar Buttons */
.nav-option {
  font-size: 1.2rem;
  margin: 30px 0;
  transition: all 0.7s;
}

.nav-option:hover {
  font-size: 1.3rem;
  color: var(--accent);
  cursor: pointer;
}

.selected {
  font-weight: bold;
}

.navbar-burger-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 650px) {
  .navbar-burger-wrapper {
    padding-left: 3vw;
    opacity: 1;
  }
}

@media screen and (min-width: 651px) {
  .navbar-burger-wrapper {
    width: 0vw;
    opacity: 0;
  }
}

.navbar-burger {
  font-size: 2rem;
  transition: all 750ms;
}

.navbar-burger:hover {
  scale: 1.1;
  cursor: pointer;
}

.navbar-hide {
  opacity: 0;
}

/* Animations */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
