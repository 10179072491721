a {
  display: flex;
  text-decoration: none;
  transition: all 500ms;
}

a:hover {
  color: var(--accent);
  scale: 1.05;
}

.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85vh;
}

.portfolio-title {
  font-size: 2.5rem;
  width: 88%;
  max-width: 1200px;
  margin: 100px 0;
}

table {
  max-width: 1200px;
  width: 90%;
  border-collapse: separate;
  border-spacing: 0;
}

td {
  padding: 15px;
  text-align: left;
  border-bottom: 0.5px solid rgb(72, 72, 72);
  color: var(--fg-secondary);
}

td:nth-child(2) {
  color: var(--fg);
  font-size: 1.1rem;
  font-weight: bold;
}

th {
  padding: 15px;
  text-align: left;
  border-bottom: 0.5px solid rgb(72, 72, 72);
}

.portfolio-skillset {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: var(--fg);
  max-width: 35vw;
}

.portfolio-github-link {
  font-size: 1.7rem;
  margin-left: 20px;
}

/* This will apply to screens with a width of 600px or less */
@media (max-width: 825px) {
  th:nth-child(3),
  td:nth-child(3) {
    display: none;
  }
}
